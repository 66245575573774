import React, { useState, useEffect } from 'react';
import {Container, Label, Table} from "semantic-ui-react";
import {subdomain} from "../libs/api";
import {Link} from "react-router-dom";

export default function SubdomainSelector () {
    const [data, setData] = useState([]);

    useEffect(() => {
        subdomain.get().then(response => {
            if(response && response.data) {
                setData(response.data);
            }
        })
    }, []);

    return <Container fluid>
        <h1 style={{textAlign: 'center', padding: '20px'}}>All Available Sub domains</h1>
        <Table size={'large'} verticalAlign={'middle'} textAlign={'center'} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>S No.</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>URL</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    data.map((subdomain, index) =>
                        <Table.Row key={subdomain.id}>
                            <Table.Cell>
                                <Label>{index + 1}</Label>
                            </Table.Cell>
                            <Table.Cell>{subdomain.name}</Table.Cell>
                            <Table.Cell><Link to={`/home${subdomain.url}`}>{subdomain.url}</Link></Table.Cell>
                        </Table.Row>
                    )}
            </Table.Body>
        </Table>
    </Container>
}