import React, { useState, useEffect } from 'react';
import {Container, Table, Label, Button, Icon} from 'semantic-ui-react';

import ModifyDialog from "./ModifyModal";

import * as api from "../../libs/api";
import {Link} from "react-router-dom";
import Header from "../Header";

const {subdomain} = api;

export default function List (props) {
    const {history} = props;
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(null);
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);

    useEffect(() => {
        subdomain.get().then(res => {
            if(res && res.data) {
                setData(res.data);
            }
        })
    }, []);

    return (<Container fluid>
        <Header {...props}/>
        <Table size={'large'} verticalAlign={'middle'} textAlign={'center'} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>S No.</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>URL</Table.HeaderCell>
                    <Table.HeaderCell style={{textAlign: 'right'}}>
                        <Button animated='vertical'  primary onClick={() => {
                            setEdit(false);
                            setModifyDialogOpen(true)
                        }}>
                            <Button.Content hidden>Add</Button.Content>
                            <Button.Content visible>
                                <Icon name='add' />
                            </Button.Content>
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                data.map((subdomain, index) =>
                <Table.Row key={subdomain.id}>
                    <Table.Cell>
                        <Label>{index + 1}</Label>
                    </Table.Cell>
                    <Table.Cell>{subdomain.name}</Table.Cell>
                    <Table.Cell><Link to={`/home${subdomain.url}`}>{subdomain.url}</Link></Table.Cell>
                    <Table.Cell>
                        <Button primary onClick={() => history.push(`/playlist/${subdomain.id}`)}>
                            <Button.Content hidden>Show Playlist</Button.Content>
                        </Button>
                        <Button animated='vertical'  primary onClick={() => {
                            setEdit(true);
                            setItem(subdomain);
                            setModifyDialogOpen(true);
                        }}>
                            <Button.Content hidden>Edit</Button.Content>
                            <Button.Content visible>
                                <Icon name='edit' />
                            </Button.Content>
                        </Button>
                        <Button animated='vertical'  secondary onClick={() => {
                            if(window.confirm(`Are you sure you want to delete sub-domain ${subdomain.name}?`))
                                api.subdomain.del(subdomain.id).then(response => {
                                    if(response && response.data) {
                                        setData(data.filter(item => +item.id !== +subdomain.id));
                                    }
                                }).catch(e => {
                                    console.log(e);

                                    alert('Delete failed!');
                                });
                        }}>
                            <Button.Content hidden>Delete</Button.Content>
                            <Button.Content visible>
                                <Icon name='delete' />
                            </Button.Content>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )}
            </Table.Body>
        </Table>
        {modifyDialogOpen && <ModifyDialog open={modifyDialogOpen} setOpen={setModifyDialogOpen} data={data} setData={setData} edit={edit} item={item}/>}
    </Container>);
}
