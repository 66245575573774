import React, {useEffect, useState} from 'react';
import {Container, Table, Label, Button, Icon} from 'semantic-ui-react';

import ModifyDialog from "./ModifyModal";

import * as api from "../../libs/api";
import Header from "../Header";

const { user } = api;

export default function List (props) {
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(null);
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);

    useEffect(() => {
        user.get().then(res => {
            if(res && res.data) {
                setData(res.data);
            }
        })
    }, []);

    return (<Container fluid>
        <Header {...props}/>
        <Table size={'large'} verticalAlign={'middle'} textAlign={'center'} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>S No.</Table.HeaderCell>
                    <Table.HeaderCell>Username</Table.HeaderCell>
                    <Table.HeaderCell style={{textAlign: 'right'}}>
                        <Button animated='vertical'  primary onClick={() => {
                            setEdit(false);
                            setModifyDialogOpen(true)
                        }}>
                            <Button.Content hidden>Add</Button.Content>
                            <Button.Content visible>
                                <Icon name='add' />
                            </Button.Content>
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {data.map((user, index) => {
                    return <Table.Row key={user.id}>
                        <Table.Cell>
                            <Label>{index + 1}</Label>
                        </Table.Cell>
                        <Table.Cell>{user.username}</Table.Cell>
                        <Table.Cell>
                            <Button animated='vertical'  primary onClick={() => {
                                setEdit(true);
                                setItem(user);
                                setModifyDialogOpen(true);
                            }}>
                                <Button.Content hidden>Edit</Button.Content>
                                <Button.Content visible>
                                    <Icon name='edit' />
                                </Button.Content>
                            </Button>
                            <Button animated='vertical'  secondary onClick={() => {
                                if(window.confirm(`Are you sure you want to delete sub-domain ${user.username}?`))
                                    api.user.del(user.id).then(response => {
                                        if(response && response.data) {
                                            setData(data.filter(item => +item.id !== +user.id));
                                        }
                                    }).catch(e => {
                                        console.log(e);

                                        alert('Delete failed!');
                                    });
                            }}>
                                <Button.Content hidden>Delete</Button.Content>
                                <Button.Content visible>
                                    <Icon name='delete' />
                                </Button.Content>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
        {modifyDialogOpen && <ModifyDialog open={modifyDialogOpen} setOpen={setModifyDialogOpen} item={item} edit={edit} data={data} setData={setData}/>}
    </Container>);
}
