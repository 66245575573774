import React, {useState, useEffect} from 'react';
import {Modal, Button, Input, Icon} from 'semantic-ui-react';
import {subdomain} from "../../libs/api";

export default function ModifyModal({setOpen, open, data, setData, edit, item}) {
    const [name, setName] = useState('');
    const [url, setURL] = useState('');

    useEffect(() => {
        if(edit && item) {
            setName(item.name);
            setURL(item.url);
        }
    }, [edit, item]);

    return (<Modal
        size={'mini'}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <Modal.Header>{edit ? 'Edit' : 'Add'} Sub Domain</Modal.Header>
        <Modal.Content style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column', height: '30vh'}}>
            <Input value={name} onChange={(e) => setName(e.target.value)}
                   placeholder='Name'/>
            <Input value={url} onChange={(e) => setURL(e.target.value)}
                   placeholder='URL'/>
        </Modal.Content>
        <Modal.Actions>
            <Button animated='vertical' primary onClick={() => {
                const payload = {
                    name,
                    url,
                };

                if(edit) {
                    subdomain.put(item.id, payload).then(response => {
                        if (response && response.data) {
                            const newData = [...data];

                            if(response && response.data) {
                                response.data.forEach(item => {
                                    const correspondingDataItem = newData.find(dataItem => +dataItem.id === +item.id);

                                    if (correspondingDataItem) {
                                        Object.keys(item).forEach(key => {
                                            correspondingDataItem[key] = item[key];
                                        });
                                    } else {
                                        newData.push(item);
                                    }
                                });

                                setData(newData);
                                setOpen(false);
                            }
                        } else {
                            alert('Edit Failed!');
                        }

                    }).catch(e => {
                        console.log(e);

                        alert('Edit Failed!');
                    });
                } else {
                    subdomain.post({
                        name,
                        url
                    }).then(response => {
                        const newData = [...data];

                        if (response && response.data) {
                            response.data.forEach(item => {
                                newData.push(item);
                            });

                            setData(newData);
                            setOpen(false)
                        } else {
                            alert('Add Failed!');
                        }
                    }).catch(e => {
                        console.log(e);

                        alert('Add Failed!');
                    });
                }

            }}>
                <Button.Content hidden>{edit ? 'Edit' : 'Add'}</Button.Content>
                <Button.Content visible>
                    <Icon name='checkmark' />
                </Button.Content>
            </Button>
            <Button animated='vertical'
                    secondary
                    onClick={() => setOpen(false)}>
                <Button.Content hidden>Discard</Button.Content>
                <Button.Content visible>
                    <Icon name='delete' />
                </Button.Content>
            </Button>
        </Modal.Actions>
    </Modal>);
}
