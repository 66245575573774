import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Home from './components/Home';
import Login from './components/Login';

import PlaylistList from './components/Playlist/List';
import SubdomainSelector from './components/SubdomainSelector';
import SubdomainList from './components/Subdomain/List';
import UserList from './components/User/List';

const requireValidTokenForAuthenticatedRoutes = (props, Component) => {
    const {history} = props;

    try {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);

        if(decodedToken.exp < (new Date().getTime()) / 1000) {
            localStorage.removeItem('token');

            history.replace('/login');
            return null
        } else {
            return <Component {...props}/>;
        }
    } catch(e) {
        localStorage.removeItem('token');
        history.replace('/login');
    }
};

export default function Routes() {
    return <Router>
        <Switch>
            <Route path={'/'} exact component={SubdomainSelector} />
            <Route path={'/home'} exact component={SubdomainSelector} />
            <Route path={'/home/:subdomainName'} component={Home}/>
            <Route path={'/login'} component={Login}/>
            <Route path={'/playlist'} exact render={(props) => requireValidTokenForAuthenticatedRoutes(props, PlaylistList)}/>
            <Route path={'/playlist/:subdomainId'} render={(props) => requireValidTokenForAuthenticatedRoutes(props, PlaylistList)}/>
            <Route path={'/subdomain'} render={(props) => requireValidTokenForAuthenticatedRoutes(props, SubdomainList)}/>
            <Route path={'/user'} render={(props) => requireValidTokenForAuthenticatedRoutes(props, UserList)}/>
        </Switch>
    </Router>
}
