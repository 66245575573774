import React, {useState, useEffect} from 'react';
import {Modal, Button, Input, Icon, Dropdown} from 'semantic-ui-react';
import {playList} from "../../libs/api";

export default function ModifyModal({setOpen, open, selectedSubDomain, subdomains, data, setData, edit, item}) {
    const [name, setName] = useState('');
    const [url, setURL] = useState('');
    const [page, setPage] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [duration, setDuration] = useState('');

    useEffect(() => {
        if(selectedSubDomain) {
            setSubdomain(selectedSubDomain);
        }

        if(edit && item) {
            setName(item.name);
            setURL(item.url);
            setPage(item.page);
            setDuration(item.duration);
        }
    }, [edit, item, selectedSubDomain]);

    return (<Modal
        size={'mini'}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <Modal.Header>{edit ? 'Edit' : 'Add'} Playlist</Modal.Header>
        <Modal.Content style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column', height: '50vh'}}>
            <Input value={name}
                   onChange={(e) => setName(e.target.value)}
                   placeholder='Name'/>
            <Input value={page}
                   type={'number'}
                   onChange={(e) => setPage(e.target.value)}
                   placeholder='Page'/>
            <Input value={url}
                   onChange={(e) => setURL(e.target.value)}
                   placeholder='URL'/>
            <Input value={duration} onChange={(e) => setDuration(e.target.value)}
                   type={'number'}
                   placeholder='Duration(in s)'/>
            <Dropdown value={subdomain}
                      fluid
                      selection
                    onChange={(e, selected) => {
                        setSubdomain(selected.value)
                    }}
                    placeholder='Select Sub-domain'
                    options={subdomains.map(subdomain => ({
                        key: subdomain.id,
                        value: subdomain.id,
                        text: subdomain.name
                    }))} />
        </Modal.Content>
        <Modal.Actions>
            <Button animated='vertical' primary onClick={() => {
                const payload = {
                    name,
                    url,
                    duration: +duration,
                    page,
                    subdomain_id: subdomain
                };

                if(edit) {
                    playList.put(item.id, payload).then(response => {
                        if (response && response.data) {
                            const newData = [...data];

                            if(response && response.data) {
                                response.data.forEach(item => {
                                    if (+item.subdomain_id === +selectedSubDomain) {
                                        const correspondingDataItem = newData.find(dataItem => +dataItem.id === +item.id);

                                        if (correspondingDataItem) {
                                            Object.keys(item).forEach(key => {
                                                correspondingDataItem[key] = item[key];
                                            });
                                        } else {
                                            newData.push(item);
                                        }
                                    }
                                });

                                setData(newData);
                            }
                            setOpen(false)
                        } else {
                            alert('Edit failed!');
                        }
                    }).catch(e => {
                        console.log(e);

                        alert('Edit Failed!');
                    });
                } else {
                    playList.post(payload).then(response => {
                        if (response && response.data) {
                            const newData = [...data];

                            response.data.forEach(item => {
                                if (+item.subdomain_id === +selectedSubDomain) {
                                    const correspondingDataItem = newData.find(dataItem => +dataItem.id === +item.id);

                                    if (correspondingDataItem) {

                                    } else {
                                        newData.push(item);
                                    }
                                }
                            });

                            setData(newData);
                            setOpen(false);
                        } else {
                            alert('Add Failed!');
                        }

                    }).catch(e => {
                        console.log(e);

                        alert('Add Failed!');
                    });
                }
            }}>
                <Button.Content hidden>{edit ? 'Edit' : 'Add'}</Button.Content>
                <Button.Content visible>
                    <Icon name='checkmark' />
                </Button.Content>
            </Button>
            <Button animated='vertical'
                    secondary
                    onClick={() => setOpen(false)}>
                <Button.Content hidden>Discard</Button.Content>
                <Button.Content visible>
                    <Icon name='delete' />
                </Button.Content>
            </Button>
        </Modal.Actions>
    </Modal>);
}
