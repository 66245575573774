import React, {useState, useEffect} from 'react';
import {Modal, Button, Input, Icon} from 'semantic-ui-react';
import {user} from "../../libs/api";

export default function ModifyModal({setOpen, open, edit, item, data, setData}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if(edit && item) {
            setUsername(item.username);
        }
    }, [edit, item]);

    return (<Modal
        size={'mini'}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <Modal.Header>{edit ? 'Edit' : 'Add'} User</Modal.Header>
        <Modal.Content style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column', height: '30vh'}}>
            <Input value={username} onChange={(e) => setUsername(e.target.value)} icon='users' iconPosition='left'
                   placeholder='Username'/>
            <Input value={password} onChange={(e) => setPassword(e.target.value)} type={'password'} icon='key'
                   iconPosition='left' placeholder='Password'/>
        </Modal.Content>
        <Modal.Actions>
            <Button animated='vertical' primary onClick={() => {
                const payload = {
                    username
                };

                if(edit) {
                    if(password && password !== "")
                        payload.password = password;

                    user.put(item.id, payload).then(response => {
                        if (response && response.data) {
                            const newData = [...data];

                            if(response && response.data) {
                                response.data.forEach(item => {
                                    const correspondingDataItem = newData.find(dataItem => +dataItem.id === +item.id);

                                    if (correspondingDataItem) {
                                        Object.keys(item).forEach(key => {
                                            correspondingDataItem[key] = item[key];
                                        });
                                    } else {
                                        newData.push(item);
                                    }
                                });

                                setData(newData);
                                setOpen(false)
                            } else {
                                alert('Edit Failed!');
                            }
                        }

                    }).catch(e => {
                        console.log(e);

                        alert('Edit Failed!');
                    });
                } else {
                    payload.password = password;

                    user.post(payload).then(response => {
                        const newData = [...data];

                        if (response && response.data) {
                            response.data.forEach(item => {
                                newData.push(item);
                            });

                            setData(newData);
                            setOpen(false)
                        } else {
                            alert('Add Failed!');
                        }
                    }).catch(e => {
                        console.log(e);

                        alert('Add Failed!');
                    });
                }

                setOpen(false);
            }}>
                <Button.Content hidden>{edit ? 'Edit' : 'Add'}</Button.Content>
                <Button.Content visible>
                    <Icon name='checkmark' />
                </Button.Content>
            </Button>
            <Button animated='vertical'
                    secondary
                    onClick={() => setOpen(false)}>
                <Button.Content hidden>Discard</Button.Content>
                <Button.Content visible>
                    <Icon name='delete' />
                </Button.Content>
            </Button>
        </Modal.Actions>
    </Modal>);
}
