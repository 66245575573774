import axios from "axios";

const getCommonHeaders = () => ({
    authorization: `Bearer ${localStorage.token}`,
    Accept: 'application/json'
});

export const get = (url) => axios(url, {
    method: "GET",
    headers: {
        ...getCommonHeaders()
    }
});

export const put = (url, data) => axios(url, {
    method: "PUT",
    data,
    headers: {
        ...getCommonHeaders()
    }
});

export const post = (url, data) => axios(url, {
    method: "POST",
    data,
    headers: {
        ...getCommonHeaders()
    }
});

export const del = (url) => axios(url, {
    method: "DELETE",
    headers: {
        ...getCommonHeaders()
    }
});
