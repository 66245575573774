import React, { useState, useEffect } from 'react';
import {Container, Card, Button, Input} from 'semantic-ui-react';
import {user} from "../libs/api";

export default function Login ({history}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        localStorage.removeItem('token');
    }, [])

  const __login = async(e) => {
    try {
      e.preventDefault();
      const response = await user.auth({ username, password });
      if(response && response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        return history.replace('/subdomain');
      } 
      throw new Error('Login Failure!');
    } catch (error) {
      console.log(error.message ?? error);
      alert('Login Failure!')
    }
  }

    return (
        <form onSubmit={__login}>
          <Container textAlign={'center'} fluid style={{height: '100vh', display: 'flex', alignItems: 'center'}} >
            <Card centered raised style={{padding: '1em'}}>
              <Card.Content>
                <Input value={username} onChange={(e) => setUsername(e.target.value)} icon='users' iconPosition='left' placeholder='Username' />
                <Input value={password} onChange={(e) => setPassword(e.target.value)} type={'password'} icon='key' iconPosition='left' placeholder='Password' />
              </Card.Content>
              <Card.Content extra>
                <div className='ui buttons'>
                  <Button type="submit" basic color='green'>
                      Login
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Container>
        </form>
    );
}
