import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Tab } from 'semantic-ui-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { playList } from "../libs/api";
import Slider from 'react-slick';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from 'react-helmet';
const settings = {
  dots: false,
  Infinity: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  waitForAnimate: false,
  fade: true
}
const capitalizeWords = (str) => str.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
export default function Home (props) {
    const params = useParams()
    const currentTimeout = useRef(null);
    const sliderRef = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState([]);
    
    const __fetchData = useCallback(async() => {
      try {
        const { subdomainName } = params;
        const response = await playList.getBySubdomainURL(subdomainName);
        if(!!response && Array.isArray(response.data)){
          setData(response.data.sort((a, b) => a.page - b.page))
          setCurrentIndex(0)
          console.log(response.data)
        }
      } catch (error) {

        return console.error(error.message ?? error)
      }
    }, [params])

    useEffect(() => {
      __fetchData()
    }, [__fetchData])

    useEffect(() => {
      if(currentTimeout && currentTimeout.current) clearTimeout(currentTimeout.current)

      if(data && data.length > 0) {
        currentTimeout.current = setTimeout(() => {
          setCurrentIndex((prev) => {
            let val = +prev;
            if(+prev < data.length - 1) val = val + 1;
            else val = 0;
            if(sliderRef.current) sliderRef.current.slickGoTo(val);
            return val
          })
        }, +data[currentIndex].duration * 1000);
      }

      return () => (currentTimeout.current ? clearTimeout(currentTimeout.current) : null);
    }, [data, currentIndex]);

    return (
      <div>
        <Helmet>
          <title>{params.subdomainName ? capitalizeWords(params.subdomainName) : 'Dashboard Playlist'}</title>
        </Helmet>
        <Slider {...settings} ref={sliderRef}  >
          {Array.isArray(data) && data.map((item, indx) => {
            return (
              <div key={indx}>
                <iframe 
                  title={`iframe-${indx}`}
                  style={{ width: "100vw", height: "90vh" }}
                  src={item.url}
                />
              </div>
            )
          })}
        </Slider>

        <div style={{height: '6vh', display: 'flex', alignItems: 'center'}}>
            <Tab
              panes={data.map(item => ({ menuItem: item.name}))}
              activeIndex={currentIndex}
              onTabChange={(e, { activeIndex }) => {
                  setCurrentIndex(activeIndex);
                  if(sliderRef.current) sliderRef.current.slickGoTo(activeIndex);
              }}
            />
        </div>
    </div>);
}
