import React, {useState, useEffect} from 'react';
import {Container, Table, Label, Button, Icon, Dropdown} from 'semantic-ui-react';

import ModifyDialog from './ModifyModal';
import Header from '../Header';

import {playList, subdomain} from "../../libs/api";

export default function List(props) {
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(null);
    const [subdomains, setSubdomains] = useState([]);
    const [selectedSubdomain, setSelectedSubdomain] = useState('');
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);

    useEffect(() => {
        subdomain.get().then(res => {
            if (res && res.data) {
                setSubdomains(res.data);

                if(props.match && props.match.params && +props.match.params.subdomainId && props.match.params.subdomainId) {
                    setSelectedSubdomain(+props.match.params.subdomainId);
                } else {
                    if (res.data[0]) {
                        setSelectedSubdomain(res.data[0].id);
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        playList.getBySubdomainId(selectedSubdomain).then(res => {
            if (res && res.data) {
                setData(res.data);
            }
        })
    }, [selectedSubdomain])

    return (<Container fluid>
        <Header {...props}/>
        <Table size={'large'} verticalAlign={'middle'} textAlign={'center'} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>S. No.</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>URL</Table.HeaderCell>
                    <Table.HeaderCell>Duration(in s)</Table.HeaderCell>
                    <Table.HeaderCell>Page</Table.HeaderCell>
                    <Table.HeaderCell style={{textAlign: 'right'}}>
                        <Dropdown value={selectedSubdomain}
                                  style={{marginRight: '10px'}}
                                  selection
                                  onChange={(e, selected) => setSelectedSubdomain(selected.value)}
                                placeholder='Select Sub-domain'
                                options={subdomains.map(subdomain => ({
                                    key: subdomain.id,
                                    value: subdomain.id,
                                    text: subdomain.name
                                }))} />
                        <Button animated='vertical'
                                primary
                                onClick={() => {
                                    setEdit(false);
                                    setModifyDialogOpen(true)
                                }}>
                            <Button.Content hidden>Add</Button.Content>
                            <Button.Content visible>
                                <Icon name='add' />
                            </Button.Content>
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {data.map((playlist, index) => <Table.Row key={playlist.id}>
                    <Table.Cell>
                        <Label>{index + 1}</Label>
                    </Table.Cell>
                    <Table.Cell>{playlist.name}</Table.Cell>
                    <Table.Cell><a href={`${playlist.url}`}>{playlist.url}</a></Table.Cell>
                    <Table.Cell>{playlist.duration}</Table.Cell>
                    <Table.Cell>{playlist.page}</Table.Cell>
                    <Table.Cell>
                        <Button animated='vertical'
                                primary
                                onClick={() => {
                                    setEdit(true);
                                    setItem(playlist);
                                    setModifyDialogOpen(true);
                                }}>
                            <Button.Content hidden>Edit</Button.Content>
                            <Button.Content visible>
                                <Icon name='edit' />
                            </Button.Content>
                        </Button>
                        <Button animated='vertical'
                                secondary
                                onClick={() => {
                                    if(window.confirm(`Are you sure you want to delete playlist ${playlist.name}?`))
                                        playList.del(playlist.id).then(response => {
                                            if(response && response.data) {
                                                setData(data.filter(item => +item.id !== +playlist.id));
                                            }
                                        }).catch(e => {
                                            console.log(e);

                                            alert('Delete failed!');
                                        });
                                }}>
                            <Button.Content hidden>Delete</Button.Content>
                            <Button.Content visible>
                                <Icon name='delete' />
                            </Button.Content>
                        </Button>
                    </Table.Cell>
                </Table.Row>)}
            </Table.Body>
        </Table>
        {modifyDialogOpen && <ModifyDialog subdomains={subdomains}
                                           edit={edit}
                                           item={item}
                                           selectedSubDomain={selectedSubdomain}
                                           data={data}
                                           setData={setData}
                                           open={modifyDialogOpen}
                                           setOpen={setModifyDialogOpen}/>}
    </Container>);
}
