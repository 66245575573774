import axios from 'axios';

import { BASE_URL } from './config';
import { get, post, put, del } from './apiMethods';

const unauthenticatedGet = (url) => axios(url, {method: "GET"});
const unauthenticatedPost = (url, data) => axios(url, {method: "POST", data});

export const playList = {
    getBySubdomainId: (subdomainId) => unauthenticatedGet(`${BASE_URL}/playlist/subdomainId/${subdomainId}`),
    getBySubdomainURL: (subdomainURL) => unauthenticatedGet(`${BASE_URL}/playlist/${subdomainURL}`),
    post: (data) => post(`${BASE_URL}/playlist`, data),
    put: (id, data) => put(`${BASE_URL}/playlist/${id}`, data),
    del: (id) => del(`${BASE_URL}/playlist/${id}`),
};

export const subdomain = {
    get: () => unauthenticatedGet(`${BASE_URL}/sub_domain`),
    post: (data) => post(`${BASE_URL}/sub_domain`, data),
    put: (id, data) => put(`${BASE_URL}/sub_domain/${id}`, data),
    del: (id) => del(`${BASE_URL}/sub_domain/${id}`),
};

export const user = {
    get: () => get(`${BASE_URL}/user`),
    auth: (data) => unauthenticatedPost(`${BASE_URL}/auth`, data),
    post: (data) => post(`${BASE_URL}/user`, data),
    put: (id, data) => put(`${BASE_URL}/user/${id}`, data),
    del: (id) => del(`${BASE_URL}/user/${id}`),
};
